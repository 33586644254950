import React from "react"
import { graphql } from "gatsby"
import Moment from "react-moment"
import Layout from "../components/Layout/layout"
import Markdown from "react-markdown"
import ImageTop from "../components/ImageTop/ImageTopArticle"

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      strapiId
      title
      content
      publishedAt
      image {
      localFile{
        publicURL
        childImageSharp {
          fixed(width: 1400, height: 933) {
            src
          }
          }
        }
      }
    }
  }
`

const Article = ({ data }) => {
  const article = data.strapiArticle
  const seo = {
    metaTitle: article.title,
    metaDescription: article.content,
    article: true
  }
  return (
    <Layout seo={seo}>
      <div>
        <ImageTop
          image={article.image}
          sizes='100vw'
          title={article.title}
        />
        <div className="uk-section">
          <div className="uk-container uk-container-small">
            <Markdown source={article.content} escapeHtml={false} />
            <hr className="uk-divider-small" />
            <div className="uk-grid-small uk-flex-left" data-uk-grid="true">
              <div className="uk-width-expand">
                <p className="uk-text-meta uk-margin-remove-top">
                  <Moment format="MMM Do YYYY">{article.published_at}</Moment>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Article