import React from 'react';
import LazyLoadingImage from "./LazyLoading"

const BlogTopArticle = ({image, sizes, title}) =>{
  return (
    <div className="BlogTopArticle">
      <LazyLoadingImage
        image={ image.localFile.childImageSharp.fixed}
        className="BlogTopImageArticle"
        sizes={sizes}
        isLazy={ false }
      />
      <div className="BlogTopText">
        <h1 className="BlogTopTitleArticle">
          {title}
        </h1>
      </div>
    </div>
  );
};
export default BlogTopArticle;
